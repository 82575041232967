footer {
    @extend header;
    color: white;
    a {
        color: white;
    }
}

footer > .wrapper {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    #footer-description {
        flex-basis: 50%;
    }
}

img#footer-gravatar {
    margin-top: 1em;
    clip-path: circle();
    width: 100px;
}

.social-media-list {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    img {
        width: 4em;
    }

    li {
        text-align: center;
        display: flex;
        flex-direction: column;
    }
}

#build-time {
    font-family: monospace;
    font-size:x-small;
    display: flex;
    justify-content: center;
    color: lighten($brand-color, 40%);
}

@include media-query($on-palm) {
    #footer-description,
    #footer-gravatar,
    #atom {
        display: none;
    }

    #license {
        font-size: xx-small;
        text-align: center;
    }
}

#footer-extra {
    display: flex;
    justify-content: space-between;
    @include media-query($on-palm) {
        justify-content: center;
    }
}

#atom {
    img {
       width: 3em;
    }
}