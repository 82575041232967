@media print { 

	.site-header,
    .site-footer,
	#disqus_thread,
    #static-comments {
        display: none;
    }

	h1 { font-size: 18pt; }
	h2 { font-size: 16pt; color: #000; }
}
