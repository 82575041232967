.post-header {
    margin: 4em auto;
}

.post-title {
    margin-bottom: $spacing-unit / 2;
    letter-spacing: -1px;
    line-height: 1.5;
    text-align: center;
    font-weight: bold;
    font-size: 4em;
    color: $header-color;

    @include media-query($on-laptop) {
        font-size: 4em;
    }

    @include media-query($on-palm) {
        font-size: 2em;
    }

}

.post-image {
    text-align: center;

    img {
        display: inline;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    ul, ol {
        margin-left: $spacing-unit / 2;
    }

    iframe {
        width: 100%;
    }

    a {
        color: darken($brand-color, 10%)
    }

    figure {
        margin: 0 auto;
        text-align: center;
        max-width: 400px;
    }
}

#related-posts {
    background: lighten($brand-color, 55%);
    padding: $spacing-unit / 2;
    a {
        color: $text-color;
    }
    * {
        margin: 0;
    }
}

.pagination {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
}

.pagination--pager.disabled {
    color: lighten($brand-color, 50%);
    pointer-events: none;
    cursor: not-allowed;
}

.pagination--pager {
    padding: 1em 2em;
    width: 50%;
    font-weight: bold;
    text-align: center;
    border: 1px solid lighten($brand-color, 50%);
    border-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination--pager:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#comments, #comments-form {
    font-size: .9rem;
    color: $grey-color-dark;
}

#comments article {
    display: flex;

    p {
        white-space: pre-wrap;
        // margin-top: 0;
    }

    blockquote {
        font-size: inherit;
        margin: 0;
    }

}

.comment-chain {
    border-bottom: 1px solid #f2f3f3;
    margin-bottom: 5px;
    background-color: lighten($brand-color, 55%);//lighten($grey-color-light, 20%);

    .sub-comment {
        margin: 5%;
        padding: 3%;
        font-size: smaller;
        background-color: $anti-color;

        p {
            margin-bottom: 0;
        }

        blockquote > p {
            padding: 10px;
        }

        @include media-query($on-palm) {
            .comment-image-wrapper {
                display: none;
            }
        }
    }
}

#comments-header {
    color: darken($brand-color, 20%);
    border-bottom: 1px solid lighten($brand-color, 50%);
    text-align: center;
    font-size: 3em;

    @include media-query($on-palm) {
        font-size: 2em;
    }
}

.comment-image-wrapper {
    box-sizing: border-box;
    max-width: 55px;
    min-width: 55px;
    margin: 0.5em;
    overflow: hidden;
    img {
        // clip-path: circle();
    }
}

.comment-body-wrapper {
    flex-grow: 1;
    padding: 5px;
    p {
        margin: 0;
    }
}

.comment-meta {
    display: flex;
    color: $grey-color;

    a {
        color: $grey-color;
    }
}

.reply {
    margin-right: $spacing-unit;
}

#comment-form {
    background: lighten($brand-color, 55%);
    color: darken($brand-color, 30%);
    margin-top: 15px;
    font-size: .9rem;
    padding: 15px;

    label {
        display: block;
        margin-bottom: 1em;
    }

    input, textarea {
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-family: $base-font-family;
        font-size: small;
        color: $grey-color-dark;
        border: 1px solid lighten($brand-color, 50%);
    }

    input:focus, textarea:focus {
        border-color: #f2f3f3;
    }

    button[type=submit] {
        font-family: $alt-font-family;
    }

    textarea {
        height: 10rem;
        resize: vertical;
        padding: 10px;
    }

    sup.required {
        color: $brand-color;
    }

    #preview {
        font-size: small;
        color: $grey-color-dark;
        padding: 10px;
        h1, h2, h3, h4, h5, h6 {
            color: $grey-color-dark;
            margin: initial;
        }
        p {
            white-space: pre-wrap;
            margin-top: 0;
        }

        blockquote {
            font-size: inherit;
        }
    }
}
