/*
 * Tag cloud sets. Numbers demonstrate repeat frequency for each tag
 */
#post-tags {
    a {
        margin-left: 0.5em;
    }
}

#tag-cloud [class^=set-] {
    color: $text-color;

    &:hover {
        color: $brand-color;
    }
}

.set-1 {
    font-size: 0.7em;
}

.set-2 {
    font-size: 0.8em;
}

.set-3 {
    font-size: 0.9em;
}

.set-4 {
    font-size: 1.0em;
}

.set-5 {
    font-size: 1.1em;
}

.set-6 {
    font-size: 1.2em;
}

.set-7 {
    font-size: 1.3em;
}

.set-8 {
    font-size: 1.4em;
}

.set-9 {
    font-size: 1.5em;
}

.set-10 {
    font-size: 1.6em;
}
