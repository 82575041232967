* {
    /*box-sizing: border-box;*/
}

html, body {
    margin: 0;
    height: 100%;
    min-height: 100%;
}

body {
    display: flex;
    flex-direction: column;

    font-family: $base-font-family;    
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $text-color;
    background-color: $background-color;
    overflow-wrap: break-word;
}

main {
  flex: auto;
}

header, footer {
    flex: none;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

h1, h2:not(.footer-heading), h3, h4, h5, h6 {
    margin-top: 2.1rem;
    color: darken($header-color, 20%);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    color: $header-color;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
    color: $grey-color;
}

ul {
    list-style-type: square;
}

ol {
    list-style-type: persify;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

a {
    color: $header-color;
    text-decoration: none;
}

blockquote {
    font-family: $alt-font-family;
    font-size: x-large;
    font-style: italic;
    
    letter-spacing: -1px;
    color: $grey-color;

    >  p {
        border-right: 4px solid lighten($brand-color, 30%);
        padding-right: $spacing-unit / 2
    }
}

pre {
    direction: ltr;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    padding: 8px 12px;
    overflow-x: auto;
    > code {
        font-size: 15px;
        border: 0;
        padding-left: 0;
    }
}

table {
    width: 100%;
    border: 1px solid #ccc;
    border-spacing: 0;
    border-collapse: collapse;
    tr {
        border-bottom: 1px solid #ddd;
    }
    td {
        padding: 5px;
    }
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
}

@counter-style abjad {
    system: additive;
    range: 1 1000;
    additive-symbols: 1000 "\63A", 900 "\638", 800 "\636", 700 "\630", 600 "\62E", 500 "\62B", 400 "\62A", 300 "\634", 200 "\631", 100 "\642", 90 "\635", 80 "\641", 70 "\639", 60 "\633", 50 "\646", 40 "\645", 30 "\644", 20 "\6A9", 10 "\649", 9 "\637", 8 "\62D", 7 "\632", 6 "\648", 5 "\FEEB", 4 "\62F", 3 "\62C", 2 "\628", 1 "\627\644\641";
}

@counter-style abjad-persian {
    system: extends persian;
    range: 1 1000;
    additive-symbols: 1000 "\63A", 900 "\638", 800 "\636", 700 "\630", 600 "\62E", 500 "\62B", 400 "\62A", 300 "\634", 200 "\631", 100 "\642", 90 "\635", 80 "\641", 70 "\639", 60 "\633", 50 "\646", 40 "\645", 30 "\644", 20 "\6AF", 10 "\649", 9 "\637", 8 "\62D", 7 "\698", 6 "\648", 5 "\FEEB", 4 "\62F", 3 "\686", 2 "\67E", 1 "\627\644\641";
    suffix: ". ";
    pad: 3 '-';
}

@counter-style persify {
    system: alphabetic;
    symbols: '۱' '۲' '۳' '۴' '۵' '۶' '۷' '۸' '۹';
    suffix: ". ";
}