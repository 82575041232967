header {
    background: linear-gradient(18deg, $brand-color, lighten($brand-color, 30%));
    background-size: 400% 400%;
    animation: AnimationName 30s ease infinite;

    @keyframes AnimationName {
        0%{background-position:50% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
}

.wrapper {
    margin: 0 auto;
    max-width: -webkit-calc(800px - (#{$spacing-unit} * 2));
    max-width:         calc(800px - (#{$spacing-unit} * 2));

    @include media-query($on-laptop) {
        width: 89vw;
    }
}

header nav {
    display: inline;
    margin-right: 1em;
    a {
        color: $anti-color;
        font-size: small;
    }
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    color: $anti-color;
}

.post-list {
    list-style: none;

    h2 {
        font-weight: bold;
    }

    .post-list-item {
        margin-bottom: $spacing-unit;
    }

    @include media-query($on-palm) {
        padding-right: 0px;
    }
}

.post-list-mini {
    list-style: none;

    a {
        color: $text-color;
    }
    .post-list-item {
        display: flex;
    }
    .list-meta-col {
        flex-basis: 120px;
        flex-shrink: 0;
    }
    .list-line {
        flex-grow: 1;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0;
    }
    @include media-query($on-palm) {
        padding-right: 1em;
    }
}

.meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.list-meta-col {
    @extend .meta;
    width: 160px;
    text-align: right;

    @include media-query(400px) {
        display: none;
    }
}

.post-list-header {
    font-size: 4em;
}

#motto {
    color: $anti-color;
    vertical-align: calc(40%);
}

.image-caption {
    text-align: center;
    color: $grey-color;
    margin-top: $spacing-unit / 3;
}

.footnotes {
    font-size: small;
    font-family: $alt-font-family;
}

.center {
    text-align: center;
}

.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

.button {
    border: none;
    padding: 5px 32px;
    background-color: lighten($brand-color, 25%);
    border-radius: 2px;
    color: $anti-color;
}

@include media-query($on-palm) {
    #motto {
        display: none;
    }
}

.a-pagination {
    font-feature-settings: "liga" 1,"lnum" 1;
    text-align: center;
    * {
        line-height: 1.3;
        font-weight: 500;
        display: inline-block;
        padding: .5em .75em;
    }
    .is-active {
        font-size: x-large;
    }
}